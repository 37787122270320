const Counter = {
  count: 0,
  decrement: () => Counter.count -= 1,
  increment: () => Counter.count += 1
}

var Hello = {
	view: function() {
		return m("main", [
			m("h1", "Counter " + Counter.count),
			m("button", { onclick: Counter.decrement }, "Decrement"),
      		m("button", { onclick: Counter.increment }, "Increment"),
		])
	}
}

m.mount(document.body, Hello)